
.footer-menu-linkler-ul {
  padding-left: 0;
  list-style: none;
}

.footer-menu-linkler-ul li {
  display: flex;
  align-items: center;
}

.footer-menu-linkler-ul li a {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  margin: 5px 0;
}

.footer-menu-linkler-ul li a .fa {
  margin-right: 10px;
}

.mid-footer-gd.sub-two-right {
  display: flex;
  justify-content: center;
}

.contact-left, .content-left, .banner-info-bg{
  text-align: left;

}

.alert-container {
  z-index: 1005;
  position: fixed;
  bottom: 0;
  color: white;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);

  
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: #333;
  border-radius: 5px; 
 

}

.alert-cookies-kapat-btn {
  background-color: red;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 20px;
}

.alert p{
  font-size: 12px;
  color: white;
  width: 90%;
}

@media only screen and (min-width: 1920px) {
  .alert {
    width: 1600px;
  }
}


#movetop{
  z-index: 1589;
}
